import React, { useEffect, useState } from "react";
import "./ComingUp.scss";
import { ScrollContainer } from "react-indiana-drag-scroll";
import "react-indiana-drag-scroll/dist/style.css";
import EventCard from "./EventCard";
import { Button } from "../ui/button";

function ComingUp() {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const events = [
    {
      date: new Date(2025, 0, 29, 19, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£5",
      title: "Práctica",
      duration: 2,
    },
    {
      date: new Date(2025, 1, 5, 19, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£5",
      title: "Class",
      duration: 1,
    },
    {
      date: new Date(2025, 1, 5, 20, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£3",
      title: "Práctica",
      duration: 1,
    },
    {
      date: new Date(2025, 1, 12, 19, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£5",
      title: "Class",
      duration: 1,
    },
    {
      date: new Date(2025, 1, 12, 20, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£3",
      title: "Práctica",
      duration: 1,
    },
    {
      date: new Date(2025, 1, 19, 19, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£5",
      title: "Class",
      duration: 1,
    },
    {
      date: new Date(2025, 1, 19, 20, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£3",
      title: "Práctica",
      duration: 1,
    },
    {
      date: new Date(2025, 1, 26, 19, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£5",
      title: "Class",
      duration: 1,
    },
    {
      date: new Date(2025, 1, 26, 20, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£3",
      title: "Práctica",
      duration: 1,
    },
    {
      date: new Date(2025, 2, 5, 19, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£5",
      title: "Class",
      duration: 1,
    },
    {
      date: new Date(2025, 2, 5, 20, 0),
      location: "5 St Mary Pl, Dundee DD1 5RB",
      price: "£3",
      title: "Práctica",
      duration: 1,
    },
  ];

  useEffect(() => {
    const today = new Date();
    const filteredEvents = events.filter((event) => event.date > today);
    setUpcomingEvents(filteredEvents);
  }, []);

  const formatDate = (date, duration = 1) => {
    const options = { day: "numeric", month: "long" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: false };
    return `${date.toLocaleDateString("en-GB", options)} @ ${date.toLocaleTimeString("en-GB", timeOptions).substring(0, 5)} - ${new Date(date.getTime() + duration * 60 * 60 * 1000).toLocaleTimeString("en-GB", timeOptions).substring(0, 5)}`;
  };

  return (
    <section className="coming-up" id="comingUp">
      <h2>Coming up</h2>
      <div className="carousel">
        <ScrollContainer>
          <div className="items">
            {upcomingEvents.map((event, index) => (
              <EventCard
                key={index}
                date={formatDate(event.date, event.duration)}
                location={event.location}
                price={event.price}
                title={event.title}
                dj={event.dj}
                teacher={event.teacher}
                eventLink={event.fbEvent}
              />
            ))}
          </div>
        </ScrollContainer>
      </div>
      <Button
        title={"↑ Back to the top ↑"}
        href={"#welcome"}
        className={"action-button"}
      />
    </section>
  );
}

export default ComingUp;
